import React from "react";
import style from "./contactus.module.css";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const ContactUs = () => {
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    phone: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    console.log(formData);
    e.preventDefault();

    const apiUrl = "https://api.zimblesystems.com/email";
    const apiKey = "rUZaJzFU8j4EFgoPVEZ774LhJwQxGsto5Ha0bZdH";

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert("Email sent successfully!");
        // You can reset the form here if needed
      } else {
        alert("Error sending email.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while sending the email.");
    }
  };
  return (
    <div className={style.superbox}>
      <div className={style.mainbox1}>
        <div className={style.content_box}>
          <p className={style.lets_connect}>Let's Connect</p>
          <h1 className={style.Contactus}>Contact Us </h1>
          <ul className={style.custom_list}>
            <li>
              Are you considering consolidating software systems <br /> and
              operations across portfolios?
            </li>
            {/* <br /> */}
            <li>
              Is modernizing your legacy system posing challenges <br /> for
              you?
            </li>
            {/* <br /> */}
            <li>
              Are you actively seeking solution partners to collaborate <br />{" "}
              and enhance your offerings?
            </li>
            {/* <br /> */}
          </ul>
          <p className={style.lets_collaborate}>
            Lets collaborate to accelerate your modernization journey.
          </p>
          <p className={style.Discover_the_possibilities}>
            Discover the possibilities, request a demo.
          </p>
        </div>

        <div className={style.form_box}>
          <div className={style.form_input_main_box}>
            <form onSubmit={handleSubmit}>
              <div>
                <label htmlFor="fname" className={style.form_label}>
                  Full Name
                </label>

                <input
                  className="form-control auto-resize"
                  id="fname"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  rows={1}
                />
              </div>
              <div>
                <label htmlFor="fname" className={style.form_label}>
                  Work Email
                </label>

                <input
                  type="email"
                  className="form-control auto-resize"
                  id="fname"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  rows={1}
                />
              </div>
              <div>
                <label htmlFor="fname" className={style.form_label}>
                  Phone Number
                </label>

                <input
                  type="number"
                  className="form-control auto-resize"
                  id="fname"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  rows={1}
                />
              </div>
              <div>
                <label htmlFor="fname" className={style.form_label}>
                  Company
                </label>

                <input
                  className="form-control auto-resize"
                  id="fname"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                  required
                  rows={1}
                />
              </div>
              <div>
                <label htmlFor="fname" className={style.form_label}>
                  Message
                </label>

                <textarea
                  className="form-control auto-resize"
                  id="fname"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  rows={5}
                />
              </div>
              <ReCAPTCHA
                sitekey="6Leyl3QoAAAAAFX2zX8IUF56byL1LJwUytvXUPdA"
                onChange={(value) => {
                  if (value) {
                    setCaptchaVerified(true);
                  }
                }}
                style={{marginTop:"15px"}}
                className={style.recaptcha}
              />
              <Button
                type="submit"
                className={style.button}
                style={{ border: "none" }}
                disabled={!captchaVerified}
              >
                Submit
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
