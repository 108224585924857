import React from "react";
import style from "./style.module.css";
import request from "../../../Assets/Logo/request.png";
import infra from "../../../Assets/Logo/infra.png";
import code from "../../../Assets/Logo/code.png";
import database from "../../../Assets/Logo/database.png";

const Pooled = () => {
  return (
    <div className={style.mainBox}>
      <div className={`${style.arrow_container1} ${style.arrow22}`}></div>
      <div className={`${style.line_container} ${style.arrow23}`}></div>
      <div className={`${style.line_container} ${style.arrow24}`}></div>

      <div className={style.pooledLeftBox}>
        <div>
          <img src={request} alt="request" />
          <p>Request</p>
        </div>
      </div>
      <div className={style.rightBox}>
        <div>
          <p>Line of Business 1+2+3</p>
          <p>LoB 1+2</p>
          <div className={`${style.rightParentBox} ${style.rightParentBox4}`}>
            <div className={style.frontBox}>
              <img src={infra} alt="infra" />
              <p>Infra</p>
            </div>
            <div>
              <img src={code} alt="code" />
              <p>Code</p>
            </div>
            <div>
              <img src={database} alt="database" />
              <p>Database</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pooled;
