import React from "react";
import style from "./termsConditions.module.css";

const TermsConditions = () => {
  return (
    <div className={style.superbox}>
      <div className={style.mainbox}>
        <div className={style.terms_condition_mainbox}>
          <div className={style.rectangle_57_1_img}>
            <img src="Rectangle 57 (1).png" alt="" />
          </div>
          <div className={style.terms_condition_contentbox}>
            <div className={style.terms_condition_second_contentbox}>
              <p className={style.legal}>Legal</p>
              <h1 className={style.Terms_Conditions}>Terms & Conditions</h1>
              <p className={style.Terms_Conditions_first_content_box}>
                These are the Terms and Conditions governing the use of this
                Service and the agreement that operates between You and the
                Company. These Terms and Conditions set out the rights and
                obligations of all users regarding the use of the Service. Your
                access to and use of the Service is conditioned on Your
                acceptance of and compliance with these Terms and Conditions.
                <br />
                <br />
                These Terms and Conditions apply to all visitors, users and
                others who access or use the Service. By accessing or using the
                Service You agree to be bound by these Terms and Conditions. If
                You disagree with any part of these Terms and Conditions then
                You may not access the Service. You represent that you are over
                the age of 18.
                <br />
                <br />
                The Company does not permit those under 18 to use the Service.
                Your access to and use of the Service is also conditioned on
                Your acceptance of and compliance with the Privacy Policy of the
                Company. Our Privacy Policy describes Our policies and
                procedures on the collection, use and disclosure of Your
                personal information when You use the Application or the Website
                and tells You about Your privacy rights and how the law protects
                You. Please read Our Privacy Policy carefully before using Our
                Service.
              </p>
              <p>
                When You create an account with Us, You must provide Us
                information that is accurate, complete, and current at all
                times. Failure to do so constitutes a breach of the Terms, which
                may result in immediate termination of Your account on Our
                Service.
                <br />
                <br />
                You are responsible for safeguarding the password that You use
                to access the Service and for any activities or actions under
                Your password, whether Your password is with Our Service or a
                Third-Party Social Media Service.
                <br />
                <br />
                You agree not to disclose Your password to any third party. You
                must notify Us immediately upon becoming aware of any breach of
                security or unauthorized use of Your account.
                <br />
                <br />
                You may not use as a username the name of another person or
                entity or that is not lawfully available for use, a name or
                trademark that is subject to any rights of another person or
                entity other than You without appropriate authorization, or a
                name that is otherwise offensive, vulgar or obscene.
              </p>
            </div>
          </div>
          <div className={style.rectangle_56_1_img}>
            <img src="Rectangle 56 (1).png" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
