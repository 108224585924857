import React from "react";
import style from "./WhatMakesUsDifferent.module.css";
import whatmakesusdifferent from "../../../Assets/Images/whatmakesusdifferent.png";

const WhatMakesUsDifferent = () => {
  const different = [
    {
      name: "— Trim the Time",
      heading: "Secure and Swift Implementation",
      content:
        "Rapid, Secure, and Resilient: Accelerate Cloud Deployments with Infrastructure as Code and Effective Change Management!",
    },
    {
      name: "— Tailored Solutions",
      heading: "Cherry Pick Solution",
      content:
        "We provide a system of interchangeable modules that can be combined in different ways to create customized solutions, offering flexibility and scalability. It enables tailored solutions by assembling modules based on specific needs or requirements.",
    },
    {
      name: "— Seize Ownership",
      heading: "Software not a Service",
      content:
        "Embrace the freedom to govern and shape your operations, ensuring data security and operational efficiency in your hands.",
    },
  ];
  return (
    <div className={style.whatMakesUsDifferent}>
      <div className={style.container}>
        <div className={style.heading}>
          <p>Functions</p>
          <h1>What Makes us Different</h1>
        </div>
        <div className={style.details}>
          <img src={whatmakesusdifferent} alt="whatmakesusdifferent" />
          <div>
            {different.map((e) => (
              <div className={style.services}>
                <p>{e.name}</p>
                <h1>{e.heading}</h1>
                <p>{e.content}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatMakesUsDifferent;
