import React from "react";
import style from "./FeaturesAndBenifits.module.css";
import security from "../../../Assets/Logo/security.png";
import facility from "../../../Assets/Logo/facility.png";
import scale from "../../../Assets/Logo/scale.png";
import cloud from "../../../Assets/Logo/cloud.png";
import nimble from "../../../Assets/Logo/nimble.png";
import currency from "../../../Assets/Logo/currency.png";
// import Background_Image from "../../../Assets/Images/Background_Image.png";

const FeaturesAndBenifits = () => {
  return (
    <div className={style.features}>
      <div className={style.container}>
        <div className={style.section1}>
          <h1>Features & Benefits</h1>
          <div className={style.eachBox}>
            <img src={security} alt="security" />
            <p>Ensures data security and full ownership.</p>
          </div>
        </div>
        <div className={style.section2}>
          <div className={style.eachBox}>
            <img src={facility} alt="facility" />
            <p>Facilitates seamless integration.</p>
          </div>
          <div className={style.eachBox}>
            <img src={scale} alt="scale" />
            <p>Scale your solutions and data with ease.</p>
          </div>
          <div className={`${style.eachBox} ${style.hidden}`}></div>
        </div>
        <div className={style.section3}>
          <div className={style.eachBox}>
            <img src={cloud} alt="cloud" />
            <p>Embraces a cloud-native approach for reduced TCO.</p>
          </div>
          <div className={style.eachBox}>
            <img src={nimble} alt="nimble" />
            <p>Nimble release management and deployment processes.</p>
          </div>
          <div className={style.eachBox}>
            <img src={currency} alt="currency" />
            <p>Simplified, tailored and transparent pricing model.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesAndBenifits;
