import React from 'react'
import style from "./CuttingEdge.module.css"

const CuttingEdge = ({title, heading}) => {
  return (
    <div className={style.cuttingEdge}>
        <div className={style.container}>
            <h6>{title}</h6>
            <h1>{heading}</h1>
        </div>
    </div>
  )
}

export default CuttingEdge