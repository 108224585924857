import React from "react";
import HeroSection from "../../Components/Functions/HeroSection/HeroSection";
import CuttingEdge from "../../Components/HomePage/CuttingEdge/CuttingEdge";
import EmbraceTransformation from "../../Components/HomePage/EmbraceTransformation/EmbraceTransformation";
import FunctionList from "../../Components/Functions/FunctionList/FunctionList";

const Functions = () => {
  return (
    <div>
      <HeroSection />
      <CuttingEdge title={"Self contained solutions"} heading={"Functions"} />
      <FunctionList />
      <EmbraceTransformation />
    </div>
  );
};

export default Functions;
