import React from 'react'
import { Routes, Route } from 'react-router-dom'
import HomePage from '../Pages/HomePage/HomePage'
import ContactUs from '../Pages/ContactUs/ContactUs'
import NoPage from '../Pages/NoPage/NoPage'
import Platform from '../Pages/Platform/Platform'
import Functions from '../Pages/Functions/Functions'
import AboutUs from '../Pages/AboutUs/AboutUs'
// import Careers from '../Pages/Careers/Careers'
// import Blog from '../Pages/Blog/Blog'
import TermsConditions from '../Pages/Terms&Conditions/TermsConditions'
import CreateProducts from '../Admin/CreateProduct/CreateProducts'
import CreateProfile from '../Admin/CreateProfile/CreateProfile'
import Login from '../Admin/Auth/Login'
import ComingSoon from '../Components/ComingSoon/ComingSoon'
// import WordFlick2 from '../Components/HomePage/HeroSection/WordFlick2'
// import WordFlick from '../Components/HomePage/HeroSection/WordFlick'

const AllRoute = () => {
  const res = JSON.parse(localStorage.getItem('zymblesystem'))
  console.log(res)
  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/platform" element={<Platform />} />
        <Route path="/functions" element={<Functions />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/careers" element={<ComingSoon />} />
        <Route path="/blog" element={<ComingSoon />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/termsconditions" element={<TermsConditions />} />
        <Route path="/admin/createproduct" element={res ? <CreateProducts /> : <Login/>} />
        <Route path='/login' element={<Login />} />
        <Route path="/admin/createprofile" element={res ? <CreateProfile /> : <Login/>} />
        <Route path='/login' element={<Login />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </>
  )
}

export default AllRoute