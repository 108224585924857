import React from "react";
import style from "./Architecture.module.css";

const Architecture = () => {
  return (
    <div className={style.architecture}>
      <div className={style.container}>
        <div className={style.firstBox}>
          <p>Zimble Systems</p>
          <div>Container Repository</div>
        </div>
        <div className={style.secondBox}>
          <p>FI / Processor Cloud Infrastructure</p>
          <div>Services Dashboard</div>
          <div>Infrastructure Dashboard</div>
          <div>Customer App Development</div>
          <div>Container Deployment</div>
          <div>Configurable API</div>
          <div>Infrastructure Normalization</div>
        </div>
        <div className={style.thirdBox}>
          <div>Customer Applications</div>
        </div>
        <div className={style.foutBox}>
          <div>API Gateway</div>
        </div>
        <div className={`${style.arrow_container1} ${style.arrow1}`}></div>
        <div className={`${style.arrow_container1} ${style.arrow2}`}></div>
        <div className={`${style.arrow_container1} ${style.arrow3}`}></div>
      </div>
    </div>
  );
};

export default Architecture;
