import React from "react";
import style from "./EmbraceTransformation.module.css";
import embrace1 from "../../../Assets/Images/Logo-1.mp4";
import embrace2 from "../../../Assets/Images/embrace2.png";
import embrace3 from "../../../Assets/Images/embrace3.png";
import embrace4 from "../../../Assets/Images/embrace4.png";
import { Link } from "react-router-dom";

const EmbraceTransformation = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className={style.embrace}>
      <div className={style.container}>
        <video autoPlay playsInline muted loop className={style.zimbleNimble}>
          <source src={embrace1} type="video/mp4" />
          {/* Add additional source elements for different video formats (e.g., WebM, Ogg) */}
        </video>
        <div className={style.embraceContent}>
          <h1>Embrace Transformation</h1>
          <p>
            Let's connect and explore potential for transformative solutions.
          </p>
          <Link
            to="/contactus"
            onClick={scrollToTop}
            className={style.embraceContentButton}
          >
            Let's Connect
          </Link>
        </div>
        <div className={style.backImage}>
          <div className={style.eachBackImage}>
            <img src={embrace2} alt="embrace2" />
          </div>
          <div className={style.eachBackImage}>
            <img src={embrace3} alt="embrace2" />
          </div>
          <div className={style.eachBackImage}>
            <img src={embrace4} alt="embrace2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmbraceTransformation;
