import React from "react";
import { Link } from "react-router-dom"; // If you are using React Router for handling routes

const PageNotFound = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>404 - Page Not Found</h1>
      <p style={styles.text}>
        Oops! The page you are looking for does not exist.
      </p>
      <Link to="/" style={styles.link}>
        Go back to the home page
      </Link>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    backgroundColor: "#f8f8f8",
  },
  heading: {
    fontSize: "3rem",
    fontWeight: "bold",
    marginBottom: "1rem",
  },
  text: {
    fontSize: "1.2rem",
    marginBottom: "2rem",
  },
  link: {
    fontSize: "1.1rem",
    color: "#007bff",
    textDecoration: "none",
  },
};

export default PageNotFound;
