import React from "react";
import { Link } from "react-router-dom";
import style from "./Footer.module.css";
import zimble from "../../Assets/Logo/Zimble.png";
import {
  // AiOutlineFacebook,
  // AiOutlineInstagram,
  AiOutlineLinkedin,
} from "react-icons/ai";
// import { RiTwitterXLine } from "react-icons/ri";
// import twitter from "../../Assets/Logo/twitter.png";

const Footer = () => {
  // Define a function to scroll to the top when a link is clicked
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={style.footer}>
      <div className={style.container}>
        <div className={style.footerLogo}>
          <img src={zimble} alt="zimble" />
          <div>
            {/* <Link to="https://www.facebook.com/zimbleSystems/" style={{ color: "#FFF" }}>
              <AiOutlineFacebook />
            </Link> */}
            {/* <Link
              to="https://twitter.com/zimbleSystems"
              style={{ color: "#FFF" }}
            >
              <RiTwitterXLine />
            </Link>             */}
            <Link
              to="https://www.linkedin.com/company/zimblesystems"
              style={{ color: "#FFF" }}
            >
              <AiOutlineLinkedin />
            </Link>
            {/* <Link
              to="https://www.instagram.com/zimblesystems/"
              style={{ color: "#FFF" }}
            >
              <AiOutlineInstagram />
            </Link> */}
          </div>
        </div>
        <div className={style.footerOptions}>
          <p>© Zimble Systems {new Date().getFullYear()}</p>
          <div>
            <Link to="/termsconditions" onClick={scrollToTop}>
              <p>Terms & Conditions</p>
            </Link>
            <Link to="/termsconditions" onClick={scrollToTop}>
              <p>Privacy Policy</p>
            </Link>
            <Link to="/platform" onClick={scrollToTop}>
              <p>Platform</p>
            </Link>
            <Link to="/functions" onClick={scrollToTop}>
              <p>Functions</p>
            </Link>
            <Link to="/aboutus" onClick={scrollToTop}>
              <p>About Us</p>
            </Link>
            <Link to="/careers" onClick={scrollToTop}>
              <p>Careers</p>
            </Link>
            <Link to="/blog" onClick={scrollToTop}>
              <p>Blog</p>
            </Link>
            <Link to="/contactus" onClick={scrollToTop}>
              <p>Contact Us</p>
            </Link>
          </div>
        </div>
        <div className={style.footerAddress}>
          <span>
            29/1029, Janatha Road, Vyttila,Kochi, Kerala - 682019, India{" "}
          </span>{" "}
          <br></br>
          <span>
            WTC, Piso 08 Oficina 08, Col. Nápoles, Benito Juárez, CDMX, C.P.
            03810, Mexico{" "}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
