import React from "react";
import style from "./HeroSection.module.css";
import HeroImage from "../../../Assets/Images/platform_hero_section_image.png";
import { VscTriangleRight } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";

const HeroSection = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/contactus");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className={style.heroSection}>
      <div className={style.container}>
        <div className={style.containerLeft}>
          <h1>Zimble Platform Bring Your Own Cloud</h1>
          <p>
            Embrace the cloud-native paradigm for unparalleled scalability,
            agility and ownership.
          </p>
          <button onClick={handleClick} className={style.letsConnect}>
            Let's Connect
            <VscTriangleRight />
          </button>
        </div>
        <div className={style.containerRight}>
          <img src={HeroImage} alt="HeroImage" />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
