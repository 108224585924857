import React from 'react'
import style from "./ComingSoon.module.css"
import functions_hero_image from "../../Assets/Images/functions_hero_image.png"
import { useNavigate } from 'react-router-dom'

const ComingSoon = () => {
    const navigate = useNavigate()
    const goBack = ()=>{
        navigate("/")
    }
  return (
    <div className={style.comingsoon}>
        <div className={style.container}>
            <img src={functions_hero_image} alt='coming'/>
            <h1>ComingSoon</h1>
            <button onClick={goBack}>Go Back</button>
        </div>
    </div>
  )
}

export default ComingSoon