import React from "react";
import style from "./SeamlesslyConnect.module.css";
import seamlesslyConnect from "../../../Assets/Images/framework2_1.mp4";


const SeamlesslyConnect = () => {
  return (
    <div className={style.seamlessly}>
      <div className={style.container}>
        <div className={style.seamlesslyConnectLeft}>
          <p>— Seamlessly Connect, Effortlessly Integrate</p>
          <h1>Integration Framework</h1>
          <ul className={style.custom_list}>
            <li>Transformation focused architecture</li>
            <li>Event Manager</li>
            <li>Configurable APIs</li>
            <li>Accelerated product life cycle</li>
            <li>Nimble release methodology</li>
          </ul>
        </div>
        {/* <img
          className={style.seamlesslyConnect}
          src={seamlesslyConnect}
          alt="seamlesslyConnect"
        /> */}
        <video autoPlay playsInline muted loop className={style.seamlesslyConnect}>
          <source src={seamlesslyConnect} type="video/mp4" />
          {/* Add additional source elements for different video formats (e.g., WebM, Ogg) */}
        </video>
      </div>
    </div>
  );
};

export default SeamlesslyConnect;
