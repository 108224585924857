import React, { useState } from "react";
import style from "./CherryPickYourSolution.module.css";
import Authorization from "../../../Assets/Logo/Authorization.png";
import cardManagement from "../../../Assets/Logo/cardManagement.png";
import Embossing from "../../../Assets/Logo/Embossing.png";
import clearing from "../../../Assets/Logo/Clearing2.png";
import keyManagement from "../../../Assets/Logo/Key_Management.png";
import notification from "../../../Assets/Logo/notification.png";
import transactionHistory from "../../../Assets/Logo/transactionHistory.png";
import eventTracker from "../../../Assets/Logo/eventTracker.png";
import TokenizationWallets from "../../../Assets/Logo/TokenizationWallets.png";
import Ledgering_Prepaid from "../../../Assets/Logo/Ledgering_Prepaid.png";
import Ledgering_Credit from "../../../Assets/Logo/Ledgering_Credit.png";
import Ledgering_Loans from "../../../Assets/Logo/Ledgering_Loans.png";
import D_secure from "../../../Assets/Logo/3D_secure.png";
import Hierarchical from "../../../Assets/Logo/Hierarchical.png";
import Unified from "../../../Assets/Logo/Unified.png";
import Analytics from "../../../Assets/Logo/Analytics.png";
import Loyalty from "../../../Assets/Logo/Loyalty.png";
import Dispute from "../../../Assets/Logo/Dispute.png";
import Originations from "../../../Assets/Logo/Originations.png";

const ItemFilter = () => {
  const [selectedCategory, setSelectedCategory] = useState("all");
  const items = [
    { name: "Authorization", category: "deployment", img: Authorization },
    { name: "Card Management", category: "deployment", img: cardManagement },
    { name: "Embossing", category: "deployment", img: Embossing },
    { name: "Key Management", category: "deployment", img: keyManagement },
    { name: "Clearing", category: "deployment", img: clearing },
    { name: "Notification", category: "deployment", img: notification },
    {
      name: "Transaction History",
      category: "deployment",
      img: transactionHistory,
    },
    { name: "Event Tracker", category: "comingSoon", img: eventTracker },
    {
      name: "Tokenization Wallets",
      category: "comingSoon",
      img: TokenizationWallets,
    },
    {
      name: "Ledgering Prepaid",
      category: "comingSoon",
      img: Ledgering_Prepaid,
    },
    { name: "Ledgering Credit", category: "comingSoon", img: Ledgering_Credit },
    { name: "Ledgering Loans", category: "comingSoon", img: Ledgering_Loans },
    { name: "3D secure", category: "comingSoon", img: D_secure },
    {
      name: "Corporate Hierarchical Systems",
      category: "comingSoon",
      img: Hierarchical,
    },
    { name: "Unified UI", category: "comingSoon", img: Unified },
    { name: "Spend Analytics", category: "comingSoon", img: Analytics },
    { name: "Loyalty", category: "comingSoon", img: Loyalty },
    {
      name: "Dispute Processing",
      category: "comingSoon",
      img: Dispute,
    },
    { name: "Originations", category: "comingSoon", img: Originations },
  ];
  const filterItems = (categories) => {
    setSelectedCategory(categories);
  };

  return (
    <div>
      <div className={style.buttonContainer}>
        <button
          className={`${style.button} ${
            selectedCategory === "all" ? style.active : ""
          }`}
          onClick={() => filterItems("all")}
        >
          All
        </button>
        <button
          className={`${style.button} ${
            selectedCategory === "deployment" ? style.active : ""
          }`}
          onClick={() => filterItems("deployment")}
        >
          Deployment Ready
        </button>
        <button
          className={`${style.button} ${
            selectedCategory === "comingSoon" ? style.active : ""
          }`}
          onClick={() => filterItems("comingSoon")}
        >
          Coming Soon
        </button>
      </div>

      <div className={style.itemBox}>
        {items.map((item) => {
          if (
            selectedCategory === "all" ||
            item.category === selectedCategory
          ) {
            return (
              <div key={item.name} className={style.item}>
                <img src={item.img} alt={item.name} />
                <p>{item.name}</p>
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default ItemFilter;
