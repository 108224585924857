import React from "react";
import HeroSection from "../../Components/HomePage/HeroSection/HeroSection";
import CuttingEdge from "../../Components/HomePage/CuttingEdge/CuttingEdge";
import AccelerateTransformation from "../../Components/HomePage/AccelerateTransformation/AccelerateTransformation";
import SeamlesslyConnect from "../../Components/HomePage/SeamlesslyConnect/SeamlesslyConnect";
import CloudNative from "../../Components/HomePage/CloudNative/CloudNative";
import CherryPickYourSolution from "../../Components/HomePage/CherryPickYourSolution/CherryPickYourSolution";
import WhatMakesUsDifferent from "../../Components/HomePage/WhatMakesUsDifferent.jsx/WhatMakesUsDifferent";
import EmbraceTransformation from "../../Components/HomePage/EmbraceTransformation/EmbraceTransformation";
// import Aos from "aos";
import "aos/dist/aos.css";

const HomePage = () => {
  // useEffect(() => {
  //   Aos.init();
  // }, []);
  return (
    <div>
      <HeroSection />
      <CuttingEdge
        title={"Cutting Edge"}
        heading={"The Future of Card Processing"}
      />
      <AccelerateTransformation />
      <SeamlesslyConnect />
      <CloudNative />
      <CherryPickYourSolution />
      <WhatMakesUsDifferent />
      <EmbraceTransformation />
    </div>
  );
};

export default HomePage;
