import React from "react";
import style from "./CloudNative.module.css";
import cloudNative from "../../../Assets/Images/Cloud_1.mp4";

const CloudNative = () => {
  return (
    <div className={style.cloudNative}>
      <div className={style.container}>
        <video autoPlay playsInline muted loop className={style.cloudNativeLeft}>
          <source src={cloudNative} type="video/mp4" />
          {/* Add additional source elements for different video formats (e.g., WebM, Ogg) */}
        </video>
        {/* <img
          className={style.cloudNativeLeft}
          src={cloudNative}
          alt="cloudNative"
        /> */}
        <div className={style.cloudNativeRight}>
          <p>— Your Success in Cloud</p>
          <h1>Cloud Native Solution</h1>
          <ul className={style.custom_list}>
            <li>Scale at lower cost</li>
            <li>Bring Your Own Cloud</li>
            <li>Enhanced Flexibility</li>
            <li>Limitless possibilities of Cloud</li>
            <li>Optimize Architecture Design</li>
            <li>Cloud Agnostic Solutions</li>
            <li>Deploy anywhere, everywhere</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CloudNative;
