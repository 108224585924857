import React from "react";
import style from "./HeroSection.module.css";
import functions_hero_image from "../../../Assets/Images/functions_hero_image.png";

const HeroSection = () => {
  return (
    <div className={style.heroSection}>
      <div className={style.container}>
        <div className={style.containerLeft}>
          <h6>Functions</h6>
          <h1>Zimble Blocks Decoupled Solutions</h1>
          <p>
            Maintain, reuse, scale and combine to achieve comprehensive
            solutions for your diverse needs
          </p>
        </div>
        <div className={style.containerRight}>
          <img  src={functions_hero_image} alt="functions_hero_image"/>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
