import React from "react";
import style from "./CherryPickYourSolution.module.css";
import ItemFilter from "./ItemFilter";

const CherryPickYourSolution = () => {
  return (
    <div className={style.cherryPick}>
      <div className={style.container} >
        <div className={style.heading}>
          <p>What fits for you</p>
          <h1>Cherry-Pick Your Solution</h1>
        </div>
        <ItemFilter />
      </div>
    </div>
  );
};

export default CherryPickYourSolution;
