import './App.css';
import Navbar from './Components/Nabvar/Navbar';
import AllRoute from './Allroutes/AllRoute';
import Footer from './Components/Footer/Footer';


function App() {
  return (
    <div className="App">
      <Navbar/>
      <AllRoute/>
      <Footer />
    </div>
  );
}

export default App;
