import React from "react";
import style from "./AccelerateTransformation.module.css";
import accelerateTransformation from "../../../Assets/Images/modular2.mp4";

const AccelerateTransformation = () => {
  return (
    <div className={style.accelerate}>
      <div className={style.container}>
        <video autoPlay playsInline muted loop className={style.accelerateTransformation}>
          <source src={accelerateTransformation} type="video/mp4" />
          {/* Add additional source elements for different video formats (e.g., WebM, Ogg) */}
        </video>
        <div className={style.accelerateTransformationRight}>
          <p>— Accelerate Transformation</p>
          <h1>Modular Build & Implementation</h1>
          <ul className={style.custom_list}>
            <li>Build solutions with modular blocks</li>
            <li>Flexible implementation</li>
            <li>Modernize the solution in small pieces</li>
            <li>Configuration-driven development approach</li>
            <li>
              Tailorable product constructs - shape your vision with precision
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AccelerateTransformation;
