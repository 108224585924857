import React from "react";
import style from "./AboutUs.module.css";
import Nitin from "../../Assets/Images/Nitin Maloth.png";
import Rajith from "../../Assets/Images/Rajith Rajan.png";
import Antoney from "../../Assets/Images/Antoney Parakkal.jpg";
import Rahul from "../../Assets/Images/Rahul Kumar.jpg";
import Jagdeesh from "../../Assets/Images/Jagdeesh Umanath.png";
import John from "../../Assets/Images/John.jpeg";
import Kenny from "../../Assets/Images/Kenny.jpeg";
import Alex from "../../Assets/Images/Alex.jpeg";
import Yunus from "../../Assets/Images/Yunus.jpeg";
import Srikantan from "../../Assets/Images/Srikantan.jpeg";
import Todd from "../../Assets/Images/Todd Lesher .png";

const AboutUs = () => {
  // const [data,setData] = useState([])
  const data = [
    {
      img: Nitin,
      country: "IN",
      name: "Nithin Maloth",
      role: "Founder / CTO",
      linkedIn: "https://www.linkedin.com/in/nithin-maloth-1a7068206/",
    },
    {
      img: Jagdeesh,
      country: "MX",
      name: "Jagdeesh Umanath",
      role: "CEO",
      linkedIn: "https://www.linkedin.com/in/jagdeesh-umanath-17713b77/",
    },
    {
      img: Rajith,
      country: "IN",
      name: "Rajith Rajan",
      role: "Platform Engineering",
      linkedIn: "https://www.linkedin.com/in/rajith-rajan/",
    },
    {
      img: Antoney,
      country: "IN",
      name: "Antoney Parakkal",
      role: "Product Engineering",
      linkedIn: "https://www.linkedin.com/in/antoney-thomas-parakkal-21110831/",
    },
    {
      img: Rahul,
      country: "IN",
      name: "Rahul Kumar",
      role: "Product Engineering",
      linkedIn: "https://www.linkedin.com/in/rahul-kumar-77872015/",
    },
    {
      img: Kenny,
      country: "GT",
      name: "Kenny Estrada",
      role: "UX Architect",
      linkedIn: "https://www.linkedin.com/in/kennyestrada/",
    },
    {
      img: John,
      country: "IN",
      name: "John Mavely",
      role: "Founder’s Office",
      linkedIn: "https://www.linkedin.com/in/john-m-65687589/",
    },
    {
      img: Yunus,
      country: "AE",
      name: "Yunus Puthenpurayil",
      role: "Marketing & Strategy",
      linkedIn: "https://www.linkedin.com/in/yunus-puthenpurayil-40665a34/",
    },
  ];
  const Advisor = [
    {
      img: Srikantan,
      country: "US",
      name: "Srikantan Madhavan",
      role: "Advisor",
      linkedIn: "https://www.linkedin.com/in/srikantan-madhavan-b789606/",
    },
    {
      img: Alex,
      name: "Alex James",
      country: "IN",
      role: "AI Advisor",
      linkedIn: "https://www.linkedin.com/in/alexpjames/",
    },
    {
      img: Todd,
      name: "Todd Lesher",
      country: "US",
      role: "Strategy and M&A ",
      linkedIn: "https://www.linkedin.com/in/toddlesher/",
    },
  ];

  // const getproducts = () => {
  //   fetch("http://localhost:8000/api/v1/product//get-profile")
  //     .then((res) => res.json())
  //     .then((res) => setData(res.products))
  //     .catch((error) => console.log(error))
  // }

  // useEffect(() => {
  //   getproducts()
  // }, [])

  return (
    <div className={style.superbox}>
      <div className={style.mainbox}>
        <div className={style.about_us_text_box}>
          <p className={style.about_us_text}>About Us</p>
          <h1 className={style.aboutus_heading}>
            Empower Innovation.
            <br />
            Drive Execution.
            <br />
            Sustain Success.
          </h1>
          <p className={style.Through_empowerment_text}>
            Through empowerment of innovative thinking, we drive seamless
            execution to achieve sustainable and enduring success. Our approach
            fosters a culture of creativity, agility, and continuous
            improvement, ensuring long-term growth and prosperity for your
            organization.
          </p>
          <div className={style.aboutus_first_image_box}>
            <img
              className={style.first_image}
              src="Feature 06.png"
              alt="first_image"
            />
          </div>
          <div className={style.aboutus_second_image_box}>
            <img
              className={style.second_image}
              src="Feature 7.png"
              alt="second_image"
            />
          </div>
          <div className={style.aboutus_third_image_box}>
            <img src="Feature 9.png" alt="second_image" />
          </div>
          <div className={style.aboutus_fourth_image_box}>
            <img
              className={style.fourth_image}
              src="Feature 8.png"
              alt="second_image"
            />
          </div>
        </div>
        <div className={style.our_team_box}>
          <p className={style.who}>Who</p>
          {/* ================================ Our team =============================== */}
          <p className={style.our_team}>Our Team</p>
          <div className={style.our_team_mainbox}>
            {data.map((member, index) => (
              <div className={style.eachBox} key={index}>
                <span className={style.photo_span}>
                  <img
                    className={style.personal_image}
                    src={member.img}
                    alt={member.name}
                  />
                </span>
                <p>
                  <span className={style.founder_name_text}>
                    <a target="blank" href={member.linkedIn}>
                      {" "}
                      {member.name}{" "}
                    </a>
                  </span>{" "}
                  <br />
                  <p>
                    <span className={style.founder_text}>
                      {member.role}
                      ({member.country})
                    </span>{" "}
                  </p>
                </p>
              </div>
            ))}
          </div>
          {/* ================================ Our Advisors =============================== */}
          <p className={style.our_team}>Our Advisors</p>
          <div className={style.our_team_mainbox}>
            {Advisor.map((member, index) => (
              <div className={style.eachBox} key={index}>
                <span className={style.photo_span}>
                  <img
                    className={style.personal_image}
                    src={member.img}
                    alt={member.name}
                  />
                </span>
                <p>
                  <span className={style.founder_name_text}>
                    <a target="blank" href={member.linkedIn}>
                      {" "}
                      {member.name}{" "}
                    </a>
                  </span>{" "}
                  <br />
                  <p>
                    <span className={style.founder_text}>
                      {member.role}
                      ({member.country})
                    </span>{" "}
                  </p>
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
