import React from "react";
import HeroSection from "../../Components/Platform/HeroSection/HeroSection";
import CuttingEdge from "../../Components/HomePage/CuttingEdge/CuttingEdge";
import Architecture from "../../Components/Platform/Ardhitecture/Architecture";
import EmbraceTransformation from "../../Components/HomePage/EmbraceTransformation/EmbraceTransformation";
import Implementation from "../../Components/Platform/Implementation/Implementation";
import CherryPickYourSolution from "../../Components/Platform/CherryPickYourSolution/CherryPickYourSolution";
import FeaturesAndBenifits from "../../Components/Platform/FeaturesAndBenifits/FeaturesAndBenifits";

const Platform = () => {
  return (
    <div>
      <HeroSection />
      <CuttingEdge title={"Governance"} heading={"Architecture"} />
      <Architecture />
      <Implementation />
      <CherryPickYourSolution />
      <FeaturesAndBenifits />
      <EmbraceTransformation />
    </div>
  );
};

export default Platform;
