import React from "react";
import style from "./HeroSection.module.css";
import WordFlick from "./WordFlick";
import { VscTriangleRight } from "react-icons/vsc";
import heroImage from "../../../Assets/Images/Hero_Image3.gif";
import { useNavigate } from "react-router-dom";
import landingZimbleVideo from "../../../Assets/Images/landing-zimble.mp4"; // Import the video

const HeroSection = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/contactus");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className={style.heroSection}>
      <div className={style.mainBox}>
        <div className={style.container}>
          <h1 className={style.heroHeadline}>
            Revolutionize Your Cards and Lending Platform
          </h1>
          <div className={style.WordFlick}>
            <WordFlick />
          </div>
          <button onClick={handleClick} className={style.letsConnect}>
            Let's Connect
            <VscTriangleRight />
          </button>
        </div>
        <video autoPlay playsInline muted loop className={style.backgroundVideo}>
          <source src={landingZimbleVideo} type="video/mp4" />
          {/* Add additional source elements for different video formats (e.g., WebM, Ogg) */}
        </video>
        <div className={style.rightSide}>
          <img src={heroImage} alt="heroImage" />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
