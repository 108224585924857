import React, { useState } from "react";
import style from "./Navbar.module.css";
import logo from "../../Assets/Logo/Zimble.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleNavItemClick = () => {
    setIsOpen(false);
  };

  return (
    <div className={style.Navbar}>
      <div className={style.container}>
        <Link to="/" className={style["nav-logo"]}>
          <img src={logo} alt="logo" />
        </Link>
        <div className={`${style["nav-items"]} ${isOpen && style.open}`}>
          <Link
            className={style.link}
            to="/platform"
            onClick={handleNavItemClick}
          >
            Platform
          </Link>
          <Link
            className={style.link}
            to="/functions"
            onClick={handleNavItemClick}
          >
            Functions
          </Link>
          <Link
            className={style.link}
            to="/aboutus"
            onClick={handleNavItemClick}
          >
            About Us
          </Link>
          <Link
            className={style.link}
            to="/careers"
            onClick={handleNavItemClick}
          >
            Careers
          </Link>
          <Link className={style.link} to="/blog" onClick={handleNavItemClick}>
            Blog
          </Link>
          <button className={style.contactButton} onClick={handleNavItemClick}>
            <Link className={style.buttonLink} to="/contactus">
              Let's Connect
            </Link>
          </button>
        </div>
        <div
          className={`${style["nav-toggle"]} ${isOpen && style.open}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className={style.bar}></div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
