import React, { useState } from "react";
import style from "./CherryPickYourSolution.module.css";
import Authorization from "../../../Assets/Logo/Authorization.png"
import cardManagement from "../../../Assets/Logo/cardManagement.png"
import Embossing from "../../../Assets/Logo/Embossing.png"
import clearing from "../../../Assets/Logo/Clearing2.png"
import dataWarehouse from "../../../Assets/Logo/dataWarehouse.png"
import keyManagement from "../../../Assets/Logo/Key_Management.png"
import notification from "../../../Assets/Logo/notification.png"
import transactionHistory from "../../../Assets/Logo/transactionHistory.png"
import eventTracker from "../../../Assets/Logo/eventTracker.png"
import TokenizationWallets from "../../../Assets/Logo/TokenizationWallets.png"
import D_secure from "../../../Assets/Logo/3D_secure.png"
import Hierarchical from "../../../Assets/Logo/Hierarchical.png"
import Unified from "../../../Assets/Logo/Unified.png"
import Analytics from "../../../Assets/Logo/Analytics.png"
import Loyalty from "../../../Assets/Logo/Loyalty.png"
import Dispute from "../../../Assets/Logo/Dispute.png"
import Originations from "../../../Assets/Logo/Originations.png"
import risk from "../../../Assets/Logo/risk.png"
import fraud from "../../../Assets/Logo/fraud.png"
import gl from "../../../Assets/Logo/gl.png"
import Collections from "../../../Assets/Logo/Collections.png"

const ItemFilter = () => {
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedType, setSelectedType] = useState("consumer");

  const items = [
    {
      name: "Authorization",
      category: ["credit", "debit", "loans", "prepaid"],
      img: Authorization,
      type: ["consumer", "corporate"],
    },
    {
      name: "Card Management",
      category: ["credit", "debit", "loans", "prepaid"],
      img: cardManagement,
      type: ["consumer", "corporate"],
    },
    {
      name: "Embossing",
      category: ["credit", "debit", "loans", "prepaid"],
      img: Embossing,
      type: ["consumer", "corporate"],
    },
    {
      name: "Key Management",
      category: ["credit", "debit", "loans", "prepaid"],
      img: keyManagement,
      type: ["consumer", "corporate"],
    },
    {
      name: "Clearing",
      category: ["credit", "debit", "loans", "prepaid"],
      img: clearing,
      type: ["consumer", "corporate"],
    },
    {
      name: "Notification",
      category: ["credit", "debit", "loans", "prepaid"],
      img: notification,
      type: ["consumer", "corporate"],
    },
    {
      name: "Transaction History",
      category: ["credit", "debit", "loans", "prepaid"],
      img: transactionHistory,
      type: ["consumer", "corporate"],
    },
    {
      name: "Tokenization Wallets",
      category: ["credit", "debit", "loans", "prepaid"],
      img: TokenizationWallets,
      type: ["consumer", "corporate"],
    },
    {
      name: "3D secure",
      category: ["credit", "debit", "loans", "prepaid"],
      img: D_secure,
      type: ["consumer", "corporate"],
    },
    {
      name: "Unified UI",
      category: ["credit", "debit", "loans", "prepaid"],
      img: Unified,
      type: ["consumer", "corporate"],
    },
    {
      name: "Loyalty",
      category: ["credit", "debit", "loans", "prepaid"],
      img: Loyalty,
      type: ["consumer", "corporate"],
    },
    {
      name: "Dispute",
      category: ["credit", "debit", "loans", "prepaid"],
      img: Dispute,
      type: ["consumer", "corporate"],
    },
    {
      name: "Originations",
      category: ["credit", "debit", "loans", "prepaid"],
      img: Originations,
      type: ["consumer", "corporate"],
    },
    {
      name: "Event Tracker",
      category: ["credit", "debit", "loans", "prepaid"],
      img: eventTracker,
      type: ["consumer", "corporate"],
    },
    {
      name: "Ledgering",
      category: ["credit", "loans", "prepaid"],
      img: transactionHistory,
      type: ["consumer", "corporate"],
    },
    {
      name: "Heirarchy System",
      category: ["credit", "debit", "loans", "prepaid"],
      img: Hierarchical,
      type: ["corporate"],
    },
    {
      name: "Spend Analytics",
      category: ["credit", "debit", "loans", "prepaid"],
      img: Analytics,
      type: ["consumer", "corporate"],
    },
    {
      name: "Datawarehouse",
      category: ["credit", "debit", "loans", "prepaid"],
      img: dataWarehouse,
      type: ["consumer", "corporate"],
    },
    {
      name: "Risk",
      category: ["credit", "loans"],
      img: risk,
      type: ["consumer", "corporate"],
    },
    {
      name: "Fraud",
      category: ["credit", "debit", "loans"],
      img: fraud,
      type: ["consumer", "corporate"],
    },
    {
      name: "GL",
      category: ["credit", "debit", "loans", "prepaid"],
      img: gl,
      type: ["consumer", "corporate"],
    },
    {
      name: "Collections",
      category: ["credit", "loans"],
      img: Collections,
      type: ["consumer", "corporate"],
    },
    {
      name: "AML",
      category: ["credit", "debit", "loans"],
      img: gl,
      type: ["consumer", "corporate"],
    },
  ];

  const filterItems = (categories, type) => {
    setSelectedCategory(categories);
    setSelectedType(type);
  };

  return (
    <div>
      <div className={style.buttonTypeContainer}>
        <button
          className={`${style.buttonType} ${
            selectedType === "consumer" ? style.active : ""
          }`}
          onClick={() => filterItems(selectedCategory, "consumer")}
        >
          Consumer
        </button>
        <button
          className={`${style.buttonType} ${
            selectedType === "corporate" ? style.active : ""
          }`}
          onClick={() => filterItems(selectedCategory, "corporate")}
        >
          Corporate
        </button>
      </div>

      <div className={style.buttonContainer}>
        <button
          className={`${style.button} ${
            selectedCategory === "all" ? style.active : ""
          }`}
          onClick={() => filterItems("all", selectedType)}
        >
          All
        </button>
        <button
          className={`${style.button} ${
            selectedCategory === "credit" ? style.active : ""
          }`}
          onClick={() => filterItems("credit", selectedType)}
        >
          Credit
        </button>
        <button
          className={`${style.button} ${
            selectedCategory === "debit" ? style.active : ""
          }`}
          onClick={() => filterItems("debit", selectedType)}
        >
          Debit
        </button>
        <button
          className={`${style.button} ${
            selectedCategory === "loans" ? style.active : ""
          }`}
          onClick={() => filterItems("loans", selectedType)}
        >
          Loans
        </button>
        <button
          className={`${style.button} ${
            selectedCategory === "prepaid" ? style.active : ""
          }`}
          onClick={() => filterItems("prepaid", selectedType)}
        >
          Prepaid
        </button>
      </div>

      <div className={style.itemBox}>
        {items.map((item) => {
          const isCategoryMatch =
            selectedCategory === "all" ||
            item.category.includes(selectedCategory);

          // Check if the selected type matches any of the types in the array
          const isTypeMatch =
            selectedType === "all" || item.type.includes(selectedType);

          if (isCategoryMatch && isTypeMatch) {
            return (
              <div key={item.name} className={style.item}>
                <img className={style.boxIcon} src={item.img} alt={item.name} />
                <p>{item.name}</p>
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default ItemFilter;
