import React, { useState, useEffect } from "react";
import style from "./WordFlick.module.css"
import ReactTextTransition from "react-text-transition";

const words = [
  { text: "Secure and Swift Implementation", color: "#E110F0" },
  { text: "Cherry Pick Solution", color: "#FFB04D" },
  { text: "Software not a Service", color: "#0ADA5D" },
  { text: "Simple and Nimble", color: "#356CFB" },
];

function DemoReactTextTransition() {
  const [textIndex, setTextIndex] = useState(0);

  useEffect(() => {
    let interval = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const currentWord = words[textIndex];

  // Override default styles to make the text normal (not bold)
  const textTransitionStyles = {
    // fontSize: "40px",
    // fontWeight: bold, // Make the text normal (not bold)
    color: currentWord ? currentWord.color : "inherit",
    zIndex: 1, // Set the z-index value here
  };

  return (
    <div>
      <section>
        {currentWord && (
          <ReactTextTransition className={style.container} style={textTransitionStyles}>
            {currentWord.text}
          </ReactTextTransition>
        )}
      </section>
    </div>
  );
}

export default DemoReactTextTransition;
