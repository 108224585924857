import React from "react";
import style from "./style.module.css";
import request from "../../../Assets/Logo/request.png";
import sharedResources from "../../../Assets/Logo/sharedResources.png";
import infra from "../../../Assets/Logo/infra.png";
import code from "../../../Assets/Logo/code.png";
import database from "../../../Assets/Logo/database.png";
import infra2 from "../../../Assets/Logo/infra2.png";
import code2 from "../../../Assets/Logo/code2.png";
import database2 from "../../../Assets/Logo/database2.png";
import infra3 from "../../../Assets/Logo/infra3.png";
import code3 from "../../../Assets/Logo/code3.png";
import database3 from "../../../Assets/Logo/database3.png";

const Hybrid = () => {
  return (
    <div className={style.mainBox}>
      <div className={`${style.arrow_container1} ${style.arrow1}`}></div>
      <div className={`${style.arrow_container1} ${style.arrow2}`}></div>
      <div className={`${style.arrow_container1} ${style.arrow3}`}></div>
      <div className={`${style.arrow_container1} ${style.arrow4}`}></div>
      <div className={`${style.line_container} ${style.arrow5}`}></div>
      <div className={`${style.line_container} ${style.arrow6}`}></div>
      <div className={`${style.line_container} ${style.arrow7}`}></div>
      <div className={`${style.line_container} ${style.arrow8}`}></div>
      <div className={`${style.line_container} ${style.arrow9}`}></div>
      <div className={`${style.line_container} ${style.arrow10}`}></div>
      <div className={`${style.line_container} ${style.arrow11}`}></div>
      <div className={`${style.line_container} ${style.arrow12}`}></div>
      <div className={style.leftBox}>
        <div>
          <img src={request} alt="request" />
          <p>Request</p>
        </div>
        <div>
          <img src={sharedResources} alt="sharedResources" />
          <p>Shared Resources</p>
        </div>
      </div>
      <div className={style.rightBox}>
        <div>
          <p>Line of Business 1</p>
          <p>LoB 1</p>
          <div className={`${style.rightParentBox} ${style.rightParentBox1}`}>
            <div>
              <img src={infra} alt="infra" />
              <p>Infra</p>
            </div>
            <div>
              <img src={code} alt="code" />
              <p>Code</p>
            </div>
            <div>
              <img src={database} alt="database" />
              <p>Database</p>
            </div>
          </div>
        </div>
        <div>
          <p>Line of Business 2</p>
          <p>LoB 2</p>
          <div className={`${style.rightParentBox} ${style.rightParentBox2}`}>
            <div>
              <img src={infra2} alt="infra" />
              <p>Infra</p>
            </div>
            <div>
              <img src={code2} alt="code" />
              <p>Code</p>
            </div>
            <div>
              <img src={database2} alt="database" />
              <p>Database</p>
            </div>
          </div>
        </div>
        <div>
          <p>Line of Business 3</p>
          <div className={`${style.rightParentBox} ${style.rightParentBox3}`}>
            <div>
              <img src={infra3} alt="infra" />
              <p>Infra</p>
            </div>
            <div>
              <img src={code3} alt="code" />
              <p>Code</p>
            </div>
            <div>
              <img src={database3} alt="database" />
              <p>Database</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hybrid;
