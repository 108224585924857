import React, { useState } from "react";
import style from "./Implementation.module.css";
import Hybrid from "./Hybrid"; // Import your Hybrid component file
import Pooled from "./Pooled"; // Import your Silo component file
import Silo from "./Silo"; // Import your Pooled component file

const Implementation = () => {
  const [selectedCategory, setSelectedCategory] = useState("hybrid");

  const selectCategory = (category) => {
    setSelectedCategory(category);
  };

  return (
    <div className={style.implementation}>
      <div className={style.heading}>
        <p>Governance</p>
        <h1>Multi-Tenant Implementation</h1>
      </div>
      <div className={style.buttonContainer}>
        <button
          className={`${style.button} ${
            selectedCategory === "pooled" ? style.active : ""
          }`}
          onClick={() => selectCategory("pooled")}
        >
          Pooled
        </button>
        <button
          className={`${style.button} ${
            selectedCategory === "silo" ? style.active : ""
          }`}
          onClick={() => selectCategory("silo")}
        >
          Silo
        </button>
        <button
          className={`${style.button} ${
            selectedCategory === "hybrid" ? style.active : ""
          }`}
          onClick={() => selectCategory("hybrid")}
        >
          Hybrid
        </button>
      </div>

      <div className={style.itemBox}>
        {selectedCategory === "pooled" && <Pooled />}
        {selectedCategory === "silo" && <Silo />}
        {selectedCategory === "hybrid" && <Hybrid />}
      </div>
    </div>
  );
};

export default Implementation;
